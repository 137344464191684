import type { CmsBlock } from '@generated/graphql'
import type { FC } from 'react'
import React from 'react'
import RenderCMS from 'src/components/RenderCMS'

interface HomePage {
  data: {
    allCmsHome: {
      edges: Array<{
        node: { sections: Array<{ name: string; data: CmsBlock['data'] }> }
      }>
    }
  }
  preview: Array<{ name: string; data: CmsBlock['data'] }> | null
}

const Above: FC<HomePage> = (props) => {
  const {
    data: { allCmsHome },
    preview,
  } = props

  const { edges } = allCmsHome
  const [sections] = edges?.map((e) => e?.node?.sections)

  return (
    <section className="page__section cms-home-components">
      <RenderCMS sections={preview ?? sections} fold="above" />
    </section>
  )
}

export default Above
